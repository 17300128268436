import "@babel/polyfill";

import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./App.scss";

import { Provider } from "mobx-react";

import { debounce } from "lodash";

import { Page } from "./engine/Wrappers";

import * as UI from "./engine/UI";

import Form from "./engine/Form";
import TestForm from "./TestForm";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
registerLocale("nl", nl);
setDefaultLocale("nl");

class SidebarTitle extends Component {
    constructor(props) {
        super(props);

        this.sidebarTitle = document.querySelector(".sidebar-title");
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.sidebarTitle
        );
    }
}

export default class App extends Component {
    state = {
        currentPageNumber: 14
    }

    constructor(props) {
        super(props);

        this.form = new Form(TestForm, {
            onChange: debounce(() => {
                if (!this.form) {
                    return;
                }

                // TODO: Autosave
                console.log(JSON.stringify(this.form.serialize()));
            }, 5000, { maxWait: 30000 })
        });
    }

    nextPagePressed = () => {
        this.setState({
            currentPageNumber: Math.min(this.state.currentPageNumber + 1, this.form.pages.length - 1)
        });
    }

    previousPagePressed = () => {
        this.setState({
            currentPageNumber: Math.max(this.state.currentPageNumber - 1, 0)
        });
    }

    render() {
        const page = this.form.pages[this.state.currentPageNumber];

        return (
            <Provider form={this.form}>
                <>
                    <SidebarTitle>{page.title}</SidebarTitle>

                    <div className="page">
                        <Page
                            page={page}
                            form={this.form}
                        />
                    </div>

                    <UI.Spacer height={20} />

                    <UI.Paginator
                        onNext={this.nextPagePressed}
                        onPrevious={this.previousPagePressed}
                        previousDisabled={this.state.currentPageNumber < 1}
                        nextDisabled={this.state.currentPageNumber >= (this.form.pages.length - 1)}
                    />
                </>
            </Provider>
        );
    }
}
