// @flow

import React, { Component } from 'react';

import { observer, inject } from "mobx-react";

import * as Fields from "./Fields";
import * as UI from "./UI";

type Props = {
};

export default inject("form")(observer(class Field extends Component<Props> {
    render() {
        let state = this.props.form.store[this.props.field.identifier];

        // Return nothing if we are invisible
        if (state && !state.visible) {
            return null;
        }

        let fieldDescriptor = this.props.form.fields[this.props.field.identifier];
        if (!fieldDescriptor) {
            return <UI.Error>Field not found: {this.props.field.identifier}</UI.Error>;
        }

        switch (fieldDescriptor.type) {
            case "builder_field":
                const subform = this.props.form.subforms[fieldDescriptor.subform];
                if (!subform) {
                    return <UI.Error>Subform not found: {fieldDescriptor.subform}</UI.Error>;
                }

                return (
                    <Fields.BuilderField
                        title={fieldDescriptor.title}
                        state={state}
                        subform={subform}
                        subformIdentifier={fieldDescriptor.subform}
                        onAddItem={fieldDescriptor.addItem}
                    />
                );
            case "text_field":
                return (
                    <Fields.TextField
                        descriptor={fieldDescriptor}
                        flex={this.props.field.flex}
                        state={state}
                        onChange={fieldDescriptor.updateValue}
                        onReset={fieldDescriptor.resetValue}
                    />
                );
            case "text_area_field":
                return (
                    <Fields.TextAreaField
                        descriptor={fieldDescriptor}
                        flex={this.props.field.flex}
                        state={state}
                        onChange={fieldDescriptor.updateValue}
                        onReset={fieldDescriptor.resetValue}
                    />
                );
            case "numeric_field":
                return (
                    <Fields.NumericField
                        descriptor={fieldDescriptor}
                        flex={this.props.field.flex}
                        state={state}
                        onChange={fieldDescriptor.updateValue}
                        onReset={fieldDescriptor.resetValue}
                    />
                );
            case "numeric_segmented_control_field":
                return (
                    <Fields.NumericSegmentedControlField
                        descriptor={fieldDescriptor}
                        flex={this.props.field.flex}
                        state={state}
                        onChange={fieldDescriptor.updateValue}
                        onReset={fieldDescriptor.resetValue}
                    />
                );
            case "date_field":
                return (
                    <Fields.DateField
                        title={fieldDescriptor.title}
                        state={state}
                        onChange={fieldDescriptor.updateValue}
                        onReset={fieldDescriptor.resetValue}
                    />
                );
            case "dropdown_field":
                return (
                    <Fields.DropdownField
                        title={fieldDescriptor.title}
                        options={fieldDescriptor.options}
                        state={state}
                        onChange={fieldDescriptor.updateValue}
                        onReset={fieldDescriptor.resetValue}
                    />
                );
            case "segmented_control_field":
                return (
                    <Fields.SegmentedControlField
                        title={fieldDescriptor.title}
                        options={fieldDescriptor.options}
                        state={state}
                        onChange={fieldDescriptor.updateValue}
                        onReset={fieldDescriptor.resetValue}
                    />
                );
            case "multi_checkbox_field":
                return (
                    <Fields.MultiCheckboxField
                        title={fieldDescriptor.title}
                        options={fieldDescriptor.options}
                        state={state}
                        onChange={fieldDescriptor.updateValue}
                        onReset={fieldDescriptor.resetValue}
                    />
                );
            case "immo_taxation_report_photos_field":
                return (
                    <Fields.ImmoTaxationReportPhotosField
                        descriptor={fieldDescriptor}
                        state={state}
                        onChange={fieldDescriptor.updateValue}
                        onReset={fieldDescriptor.resetValue}
                    />
                );
            default:
                return (
                    <UI.FieldWrapper title={fieldDescriptor.title}>
                        <UI.Error>Unsupported field type: {fieldDescriptor.type}</UI.Error>
                    </UI.FieldWrapper>
                );
        }
    }
}));
