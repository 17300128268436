// @flow

import React, { Component, PureComponent } from "react";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import NumberFormat from "react-number-format";

import {
    DragDropContextProvider,
    DragSource,
    DropTarget
} from "react-dnd";
import HTML5Backend, { NativeTypes } from "react-dnd-html5-backend";

import classnames from "classnames";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faTimes,
    faPlus,
    faCalendar
} from "@fortawesome/free-solid-svg-icons";

library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faChevronDown);
library.add(faTimes);
library.add(faPlus);
library.add(faCalendar);

// Function to transform flex values from React Native to CSS
function transformFlex(flex) {
    if (flex === 0 || flex === "0") {
        return "none";
    }

    if (typeof flex === "number") {
        return flex.toString();
    }

    return flex;
}

// Take an array of components and insert spaces inbetween.
export function spacePad(components, props) {
    let result = [];

    components.forEach(function(component, index) {
        if (index !== 0) {
            result.push(<Spacer key={"spacer" + index} {...props} />);
        }

        result.push(component);
    });

    return result;
}

// Horizontal or vertical spacer
export class Spacer extends PureComponent<{height?: number, width?: number}> {
    render() {
        return <div style={{height: this.props.height, width: this.props.width}} />;
    }
}

export class Error extends Component<{}> {
    render() {
        return (
            <span className="error">{this.props.children}</span>
        )
    }
}

export class Label extends Component<{}> {
    render() {
        return (
            <span className="label">{this.props.children}</span>
        )
    }
}

export class Title extends Component<{}> {
    render() {
        return (
            <span className="title">{this.props.children}</span>
        )
    }
}

export class Placeholder extends Component<{}> {
    render() {
        return (
            <span className="placeholder">{this.props.children}</span>
        )
    }
}

export class Flex extends Component<{}> {
    render() {
        return (
            <div className="flex">
                {this.props.children}
            </div>
        );
    }
}

export class Box extends Component<{}> {
    static defaultProps = {
        flex: 0,
        flexDirection: "column"
    }

    render() {
        const classes = classnames("box", {
            "hidden": this.props.hidden,
            "box-flex-row": this.props.flexDirection === "row",
            "box-flex-column": this.props.flexDirection === "column"
        });

        return (
            <div className={classes} style={{flex: transformFlex(this.props.flex)}}>
                {this.props.children}
            </div>
        )
    }
}

export class Panel extends Component<{}> {
    static defaultProps = {
        flex: 0,
        flexDirection: "column"
    }

    render() {
        const classes = classnames("panel", {
            "hidden": this.props.hidden,
            "box-flex-row": this.props.flexDirection === "row",
            "box-flex-column": this.props.flexDirection === "column"
        });

        return (
            <div className={classes} style={{flex: transformFlex(this.props.flex)}}>
                {this.props.children}
            </div>
        );
    }
}

export class Row extends Component<{}> {
    render() {
            //<View style={[styles.row, this.props.fill && styles.rowFill]}>
        return (
            <div className="row">
                {this.props.children}
            </div>
        )
    }
}

export class EmptySpace extends Component<{}> {
    render() {
        if (!!this.props.flex) {
            // TODO: Memoize the flex style object
            return <div style={{flex: transformFlex(this.props.flex)}} />;
        } else {
            return <div className="flex" />;
        }
    }
}

export class FieldWrapper extends Component<{}> {
    static defaultProps = {
        flex: 1
    }

    render() {
        let style = {
            flex: transformFlex(this.props.flex)
        };

        if (this.props.stretch) {
            style.display = "flex";
            style.flexDirection = "column";
        }

        // TODO: Render validation messages
        // TODO: Render hint

        return (
            <div className="field-wrapper" style={style}>
                {this.props.title && <Label>{this.props.title}</Label>}
                {this.props.title && <Spacer height={5} />}
                {this.props.children}
            </div>
        )
    }
}

type ButtonProps = {
    title?: string,
    icon?: string,
    disabled: boolean,
    onPress: () => void
};

export class Button extends Component<ButtonProps> {
    static defaultProps = {
        disabled: false
    }

    render() {
        return (
            <button
                className="button"
                onClick={this.props.onPress}
                disabled={this.props.disabled}
            >
                {!!this.props.icon && <div className="icon"><FontAwesomeIcon icon={this.props.icon} /></div>}
                {!!this.props.title && <span>{this.props.title}</span>}
            </button>
        )
    }
}

type CheckboxProps = {
    title?: string,
    disabled: boolean,
    value: boolean,
    onChange: (value: boolean) => void
};

export class Checkbox extends Component<CheckboxProps> {
    handleChange = (event) => {
        this.props.onChange(event.target.checked);
    }

    render() {
        return (
            <label className="checkbox">
                <input type="checkbox" checked={this.props.value} onChange={this.handleChange} />
                <div className="indicator" />
                {this.props.title}
            </label>
        );
    }
}

export class DatePicker extends Component<{}> {
    static defaultProps = {
        placeholder: "Kies een datum"
    }

    handleChange = (value) => {
        console.log(value);
        this.props.onChange(value);
    }

    render() {
        return (
            <div className="date-picker">
                <div className="icon">
                    <FontAwesomeIcon icon="calendar" />
                </div>
                <div className="wrapper">
                    <ReactDatePicker
                        className="picker"
                        locale="nl"
                        selected={this.props.value}
                        onChange={this.handleChange}
                        disabled={this.props.disabled}
                        placeholderText={this.props.placeholder}
                        dateFormat="PPP"
                    />
                </div>
            </div>
        )
    }
}

export class Dropdown extends Component<{}> {
    static defaultProps = {
        options: [],
    }

    handleSelect = (event) => {
        this.props.onChange(this.props.options[event.target.value]);
    }

    render() {
        let options = [];
        this.props.options.forEach((option, index) => {
            options.push(
                <option key={"option" + index} value={index}>{option.title}</option>
            );
        });

        return (
            <div className="dropdown">
                <select
                    onChange={this.handleSelect}
                    disabled={this.props.disabled}
                >
                    {options}
                </select>
                <div className="icon">
                    <FontAwesomeIcon icon="chevron-down" />
                </div>
            </div>
        )
    }
}

type InputProps = {
    value: string,

    disabled?: boolean,

    flex?: number,

    //secureTextEntry?: boolean,
    placeholder?: string,
    onChange?: Function,
    onSubmit?: Function,

    returnKeyType?: ReturnKeyType,

    showResetButton?: boolean,
    onReset?: () => void,

    prefixUnit?: string,
    suffixUnit?: string
};

export class Input extends Component<InputProps> {
    static defaultProps = {
        placeholder: "Voer een waarde in",
    }

    handleChange = (event) => {
        this.props.onChange(event.target.value);
    }

    render() {
        let prefix = null;
        let suffix = null;
        let resetButton = null;

        if (this.props.showResetButton) {
            resetButton = (
                <button className="reset" onClick={this.props.onReset}>
                    <FontAwesomeIcon icon="times" />
                </button>
            );
        }

        if (this.props.prefixUnit) {
            prefix = (
                <div className="unit">
                    {this.props.prefixUnit}
                </div>
            );
        }

        if (this.props.suffixUnit) {
            suffix = (
                <div className="unit">
                    {this.props.suffixUnit}
                </div>
            );
        }

        return (
            <div className="text-input" style={!!this.props.flex ? { flex: transformFlex(this.props.flex) } : null}>
                {resetButton}
                {prefix}
                <input
                    onChange={this.handleChange}
                    value={this.props.value || ""}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                />
                {suffix}
            </div>
        )
    }
}

type TextAreaProps = {
    value: string,

    disabled?: boolean,

    flex?: number,

    placeholder?: string,
    onChange?: Function,

    showResetButton?: boolean,
    onReset?: () => void,
};

export class TextArea extends Component<TextAreaProps> {
    static defaultProps = {
        placeholder: "Voer een waarde in",
    }

    handleChange = (event) => {
        this.props.onChange(event.target.value);
    }

    render() {
        return (
            <div className="text-area" style={!!this.props.flex ? { flex: transformFlex(this.props.flex) } : null}>
                <textarea
                    onChange={this.handleChange}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                />
            </div>
        )
    }
}

export class NumericInput extends Component<InputProps> {
    static defaultProps = {
        placeholder: "Voer een waarde in",
        precision: 0
    }

    constructor(props) {
        super(props);

        this.numberFormat = React.createRef();
    }

    handleChange = (event) => {
        // Workaround because react-number-format's onValueChange also triggers
        // on prop change
        const numberFormat = this.numberFormat.current;
        const values = numberFormat.getValueObject(numberFormat.state.value, numberFormat.state.numAsString);
        this.props.onChange(values.floatValue);
    }

    render() {
        let prefix = null;
        let suffix = null;
        let resetButton = null;

        if (this.props.showResetButton) {
            resetButton = (
                <button className="reset" onClick={this.props.onReset}>
                    <FontAwesomeIcon icon="times" />
                </button>
            );
        }

        if (this.props.prefixUnit) {
            prefix = (
                <div className="unit">
                    {this.props.prefixUnit}
                </div>
            );
        }

        if (this.props.suffixUnit) {
            suffix = (
                <div className="unit">
                    {this.props.suffixUnit}
                </div>
            );
        }

        return (
            <div className="numeric-input" style={!!this.props.flex ? { flex: transformFlex(this.props.flex) } : null}>
                {resetButton}
                {prefix}
                <NumberFormat
                    ref={this.numberFormat}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={this.props.precision}
                    fixedDecimalScale
                    onChange={this.handleChange}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    placeholder={this.props.disabled ? "" : this.props.placeholder}
                />
                {suffix}
            </div>
        )
    }
}

export class NumericSegmentedControl extends Component<{}> {
    static defaultProps = {
        lowestSegment: 1,
        highestSegment: 7,
        placeholder: "Andere: vul in",
        disabled: false
    }

    constructor() {
        super();

        this.handlers = {};
        this.numberFormat = React.createRef();

        this.state = {
            numberFormatUsed: false
        };
    }

    handleSelect = (index) => {
        this.props.onChange(index);

        this.setState({
            numberFormatUsed: false
        });
    }

    handleChange = (event) => {
        // Workaround because react-number-format's onValueChange also triggers
        // on prop change
        const numberFormat = this.numberFormat.current;
        const values = numberFormat.getValueObject(numberFormat.state.value, numberFormat.state.numAsString);
        this.props.onChange(values.floatValue);

        this.setState({
            numberFormatUsed: true
        });
    }

    render() {
        let options = [];

        for (let segment = this.props.lowestSegment; segment <= this.props.highestSegment; ++segment) {
            let selected = this.props.value === segment && !this.state.numberFormatUsed;

            if (!this.handlers[segment]) {
                this.handlers[segment] = this.handleSelect.bind(this, segment);
            }

            options.push(
                <button
                    key={"option" + segment}
                    className={selected ? "selected" : undefined}
                    onClick={this.handlers[segment]}
                >
                    {segment}
                </button>
            );
        }

        let numberFormatValue = null;
        if (this.state.numberFormatUsed || this.props.value < this.props.lowestSegment || this.props.value > this.props.highestSegment) {
            numberFormatValue = this.props.value;
        }

        return (
            <div className="numeric-segmented-control">
                {options}
                <NumberFormat
                    ref={this.numberFormat}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={0}
                    fixedDecimalScale
                    onChange={this.handleChange}
                    value={numberFormatValue}
                    disabled={this.props.disabled}
                    placeholder={this.props.disabled ? "" : this.props.placeholder}
                />
            </div>
        );
    }
}

export class SegmentedControl extends Component<{}> {
    constructor() {
        super();

        this.handlers = {};
    }

    handleSelect = (index) => {
        this.props.onChange(this.props.options[index]);
    }

    render() {
        let options = [];

        this.props.options.forEach((option, index) => {
            let selected = this.props.value === option.identifier;

            if (!this.handlers[index]) {
                this.handlers[index] = this.handleSelect.bind(this, index);
            }

            options.push(
                <button
                    key={"option" + index}
                    className={selected ? "selected" : undefined}
                    onClick={this.handlers[index]}
                >
                    {option.title}
                </button>
            );
        });

        return (
            <div className="segmented-control">
                {options}
            </div>
        );
    }
}

export class MultiCheckbox extends Component<{}> {
    static defaultProps = {
        columns: 2,
        value: []
    }

    constructor() {
        super();

        this.handlers = {};
    }

    handleChange = (index, checked) => {
        console.log("on change: ", index, checked);

        const candidate = this.props.options[index];

        if (checked && !this.props.value.includes(candidate)) {
            this.props.onChange(this.props.value.concat([candidate]));
        } else if (!checked) {
            this.props.onChange(this.props.value.filter(function(option) {
                return option !== candidate;
            }));
        }
    }

    render() {
        const rowCount = Math.ceil(this.props.options.length / this.props.columns)
        let rows = [];

        for (let row=0; row<rowCount; ++row) {
            let options = [];

            for (let column=0; column<this.props.columns; ++column) {
                const index = row * this.props.columns + column;
                const key = "option" + index;

                if (index < this.props.options.length) {
                    const option = this.props.options[index];

                    if (!this.handlers[index]) {
                        this.handlers[index] = this.handleChange.bind(this, index);
                    }

                    options.push(
                        <Box flex={1} key={key} flexDirection="row">
                            <Checkbox
                                title={option.title}
                                value={this.props.value.includes(option)}
                                onChange={this.handlers[index]}
                            />
                        </Box>
                    );
                } else {
                    options.push(
                        <Box key={key} />
                    );
                }
            }

            rows.push(
                <Row key={"row" + row}>
                    {options}
                </Row>
            );
        }

        return (
            <div className="multi-checkbox">
                {rows}
            </div>
        );
    }
}

type PaginatorProps = {
    onPrevious: () => void,
    onNext: () => void,
    previousDisabled: boolean,
    nextDisabled: boolean
};

export class Paginator extends Component<PaginatorProps> {
    static defaultProps = {
        previousDisabled: false,
        nextDisabled: false
    }

    render() {
        return (
            <div className="paginator">
                <button className={[this.props.previousDisabled && "disabled"]} onClick={this.props.onPrevious} disabled={this.props.previousDisabled}>
                    <FontAwesomeIcon icon="chevron-left" />
                </button>

                <button className={[this.props.nextDisabled && "disabled"]} onClick={this.props.onNext} disabled={this.props.nextDisabled}>
                    <FontAwesomeIcon icon="chevron-right" />
                </button>
            </div>
        )
    }
}

const photoDragDropType = "immo-taxation-report-photo";

const dragCollect = function(connect, monitor) {
    // Note: this function is called many times so it must be efficient.
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    };
};

const dragSpec = {
    beginDrag(props) {
        return {
            //photo: props.photo,
            slot: props.slot
        };
    },

    canDrag(props, monitor) {
        // TODO: Return false if we don't have a photo
        return true;
    },
};

const dropCollect = function(connect, monitor) {
    // Note: this function is called many times so it must be efficient.
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
};

const dropSpec = {
    drop(props, monitor, component) {
        const item = monitor.getItem();

        if (item.files?.length > 0) {
            console.log("TODO: this is a file");
            props.onUpload(props.slot);
        } else {
            // Swap with source slot
            props.onSwap(props.slot, item.slot);
        }
    },

    canDrop(props, monitor) {
        if (monitor.getItem()?.slot === props.slot) {
            return false;
        }

        return true;
    }
};

const Photo = DropTarget([NativeTypes.FILE, photoDragDropType], dropSpec, dropCollect)(DragSource(photoDragDropType, dragSpec, dragCollect)(
    class Photo extends Component<{}> {
        handleEmptyClick = () => {
            this.props.onUpload(this.props.slot);
        }

        render() {
            // TODO: Add delete button
            if (this.props.photo) {
                return this.props.connectDropTarget(
                    <div className={classnames("immo-photo", {"immo-photo-drop-hover": this.props.isOver && this.props.canDrop})}>
                        <div>
                            {this.props.connectDragSource(
                                <img src={this.props.photo.url} alt="" className={classnames({"immo-photo-dragging": this.props.isDragging})} />
                            )}
                        </div>
                    </div>
                );
            } else {
                return this.props.connectDropTarget(
                    <div className={classnames("immo-empty-photo", {"immo-photo-drop-hover": this.props.isOver})} onClick={this.handleEmptyClick}>
                        <FontAwesomeIcon icon="plus" />
                    </div>
                );
            }
        }
    }
));

export class ImmoTaxationReportPhotosField extends Component<{}> {
    render() {
        return (
            <DragDropContextProvider backend={HTML5Backend}>
                <Box flex={1}>
                    <div className="immo-photo-row immo-photo-row-big">
                        <Photo slot={0} photo={this.props.photos[0]} onSwap={this.props.onSwap} onUpload={this.props.onUpload} />
                    </div>

                    <div className="immo-photo-row">
                        <Photo slot={1} photo={this.props.photos[1]} onSwap={this.props.onSwap} onUpload={this.props.onUpload} />
                        <Photo slot={2} photo={this.props.photos[2]} onSwap={this.props.onSwap} onUpload={this.props.onUpload} />
                    </div>

                    <div className="immo-photo-row">
                        <Photo slot={3} photo={this.props.photos[3]} onSwap={this.props.onSwap} onUpload={this.props.onUpload} />
                        <Photo slot={4} photo={this.props.photos[4]} onSwap={this.props.onSwap} onUpload={this.props.onUpload} />
                    </div>

                    <div className="immo-photo-row">
                        <Photo slot={5} photo={this.props.photos[5]} onSwap={this.props.onSwap} onUpload={this.props.onUpload} />
                        <Photo slot={6} photo={this.props.photos[6]} onSwap={this.props.onSwap} onUpload={this.props.onUpload} />
                    </div>
                </Box>
            </DragDropContextProvider>
        );
    }
}
