// @flow

import { observable, action, toJS } from "mobx";

import RuleContext from "./RuleContext";

type FormCallbacks = {
    onChange?: (identifier) => void
};

export default class Form {
    constructor(formDescriptor, values = null, callbacks: FormCallbacks = {}) {
        this.fields = { ...formDescriptor.fields }; // Shallow copy so we can modify its contents
        this.callbacks = callbacks;

        this.rules = formDescriptor.rules || {};
        this.ruleDependencies = formDescriptor.rule_dependencies || {};
        this.pages = formDescriptor.pages || [];
        this.view = formDescriptor.view || []; // for subforms
        this.subforms = formDescriptor.subforms || {};

        this.store = observable({});

        this.ruleContext = new RuleContext(this.store);

        // Augment fields with updaters and resetters
        for (let identifier in this.fields) {
            // Make a shallow copy of the field and add properties
            this.fields[identifier] = {
                ...this.fields[identifier],

                updateValue: this.updateValue.bind(this, identifier),
                resetValue: this.resetValue.bind(this, identifier),
                addItem: this.addItem.bind(this, identifier)
            };

            // Apply default value if present
            if ("default" in this.fields[identifier]) {
                if (!this.store[identifier]) {
                    this.store[identifier] = this.createState();
                }

                const value = this.ruleContext.makeValue(this.fields[identifier].default);

                console.log("Setting " + identifier + " to: " + JSON.stringify(value));

                this.store[identifier].value = value;
            }
        }

        // Deserialize provided values if present
        if (values !== null) {
            this.deserialize(values);
        }

        // Initial execution of all rules
        this.executeDependencyChain("_all");
    }

    serialize() {
        let result = {};

        for (let identifier in this.fields) {
            const state = this.store[identifier];
            if (!state) {
                continue;
            }

            if (state.value || state.value === null) {
                result[identifier] = toJS(state.value);
            } else if (Array.isArray(state.items)) {
                result[identifier] = state.items.map(function(item) {
                    return item.form.get().serialize();
                });
            }
        }

        return result;
    }

    // Deserialize from a values object. Replaces any existing state objects
    // for the provided values, so be sure to execute the _all dependency chain
    // after calling this.
    deserialize(values) {
        // Load values
        for (let identifier in this.fields) {
            const value = values[identifier];

            // Only proceed when we have a value to read
            if (value === undefined) {
                continue;
            }

            this.store[identifier] = this.createState();

            const field = this.fields[identifier];
            if (field.type === "builder_field") {
                // Special treatment for builder fields
                this.store[identifier].items = value.map(subValues => {
                    return this.createSubformItem(identifier, field.subform, subValues);
                });
            } else {
                this.store[identifier].value = value;
            }
        }
    }

    executeRule(identifier) {
        const rule = this.rules[identifier];
        if (!rule) {
            return;
        }

        console.log("Evaluating rule " + identifier);

        let result = this.ruleContext.evaluate(rule.expression);

        console.log(result);

        // Create the state object if we don't have it yet
        if (!this.store[rule.target_identifier]) {
            this.store[rule.target_identifier] = this.createState();
        }

        // Ensure we write to the computed property
        let property = rule.target_property;

        switch (rule.target_property) {
            case "value":
                property = "computedValue";
                break;
            case "visible":
                result = this.ruleContext.extractBoolean(result);
                break;
            case "disabled":
                result = this.ruleContext.extractBoolean(result);
                break;
            default:
                break;
        }

        this.store[rule.target_identifier][property] = result;

        if (this.callbacks.onChange) {
            this.callbacks.onChange(rule.target_identifier + "." + rule.target_property);
        }
    }

    // Execute the chain of rules depending on the identifier
    executeDependencyChain(identifier) {
        const chain = this.ruleDependencies[identifier];
        if (!chain) {
            return;
        }

        for (let ruleIdentifier of chain) {
            this.executeRule(ruleIdentifier);
        }
    }

    createState() {
        return {
            // Value and computed value, used by fields
            computedValue: null,
            value: null,

            // Visibility, used by fields and UI components
            visible: true,

            // Disability, used by fields
            disabled: false,

            // TODO: Validation state/messages

            // Child items, used by subform builders
            items: null,

            // Returns the value that should be shown on screen
            get effectiveValue() {
                return this.value || this.computedValue;
            },

            // True if the computed value has been overruled
            get overruled() {
                return !!this.computedValue && !!this.value;
            }
        };
    }

    // Create a subform collection item from the owner's identifier, a subform
    // identifier and an optional value object. Returns null on failure.
    createSubformItem(identifier, subformIdentifier, values) {
        // Look up subform
        const subform = this.subforms[subformIdentifier];
        if (!subform) {
            console.log("Error: could not find subform with identifier " + subformIdentifier);
            return null;
        }

        // Instantiate a new instance of the subform
        // TODO: Pass value to form constructor if present
        const form = new Form(subform, values, {
            onChange: this.handleSubformChange.bind(this, identifier + ".items")
        });

        // Add instance to collection
        let item = observable({
            // The subform
            form: observable.box(form, { deep: false }),

            // Shortcut to the store
            state: form.store,

            // A unique identifier for use with React
            key: "" + Math.floor(Math.random() * Math.floor(1e7))
        });

        // Bind the removal function
        item.removeItem = observable.box(this.removeItem.bind(this, identifier, item), { deep: false });

        return item;
    }

    updateValue = action((identifier, value) => {
        if (!this.store[identifier]) {
            this.store[identifier] = this.createState();
        }

        console.log("Setting " + identifier + " to: " + JSON.stringify(value));

        this.store[identifier].value = value;

        // Fire callback
        if (this.callbacks.onChange) {
            this.callbacks.onChange(identifier + ".value");
        }

        // Execute rules
        this.executeDependencyChain(identifier + ".value");
    })

    resetValue = action((identifier) => {
        this.store[identifier].value = null;

        // Execute rules
        this.executeDependencyChain(identifier + ".value");
    })

    addItem = action((identifier, subformIdentifier) => {
        // Create a subform item
        const item = this.createSubformItem(identifier, subformIdentifier);

        // Initialize the state if it wasn't created already
        if (!this.store[identifier]) {
            let state = this.createState();
            state.items = [];
            this.store[identifier] = state;
        } else if (!this.store[identifier].items) {
            // Initialize the items collection if it isn't present already
            this.store[identifier].items = [];
        }

        this.store[identifier].items.push(item);

        // TODO: Execute dependency chains for count and subform field aggregations
        this.executeDependencyChain(identifier + ".items");
    })

    removeItem = action((identifier, item) => {
        let items = this.store[identifier]?.items;
        if (!items) {
            console.log("Error: could not find item for removal (no collection present)");
            return;
        }

        if (!items.remove(item)) {
            console.log("Error: could not find item for removal");
            return;
        }

        // TODO: Execute dependency chains for count and subform field aggregations
        this.executeDependencyChain(identifier + ".items");
    })

    handleSubformChange = action((identifier, subIdentifier) => {
        console.log("subform change: " + identifier + "." + subIdentifier);

        this.executeDependencyChain(identifier + "." + subIdentifier);
    })
}
