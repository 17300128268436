// @flow

import React, { Component } from "react";

import { Provider, observer } from "mobx-react";

import { SubformView } from "./Wrappers";

import * as UI from "./UI";

type TextFieldValue = {
    string: string
};

type TextFieldProps = {
    value: TextFieldValue,
    overruled: boolean,
    disabled: boolean,
    onChange: (value: TextFieldValue) => void,
    onReset: () => void
};

export const TextField = observer(class TextField extends Component<TextFieldProps> {
    static defaultProps = {
        disabled: false
    };

    handleChange = (value: string) => {
        this.props.onChange({
            type: "string",
            string: value
        });
    }

    render() {
        let value = this.props.state?.effectiveValue?.string;

        const disabled = this.props.descriptor.disabled || this.props.state?.disabled;

        // TODO: Render validation messages
        // TODO: Render hint

        return (
            <UI.FieldWrapper flex={this.props.flex} title={this.props.descriptor.title}>
                <UI.Input
                    unit={this.props.descriptor.prefixUnit}
                    suffixUnit={this.props.descriptor.unit}
                    value={value}
                    disabled={disabled}
                    showResetButton={this.props.state?.overruled}
                    onChange={this.handleChange}
                    onReset={this.props.onReset}
                />
            </UI.FieldWrapper>
        )
    }
});

export const TextAreaField = observer(class TextAreaField extends Component<TextFieldProps> {
    static defaultProps = {
        disabled: false
    };

    handleChange = (value: string) => {
        this.props.onChange({
            type: "string",
            string: value
        });
    }

    render() {
        let value = this.props.state?.effectiveValue?.string;

        const disabled = this.props.descriptor.disabled || this.props.state?.disabled;

        // TODO: Render validation messages
        // TODO: Render hint

        return (
            <UI.FieldWrapper flex={this.props.flex} title={this.props.descriptor.title} stretch>
                <UI.TextArea
                    value={value}
                    disabled={disabled}
                    placeholder={this.props.descriptor.placeholder}
                    showResetButton={this.props.state?.overruled}
                    onChange={this.handleChange}
                    onReset={this.props.onReset}
                />
            </UI.FieldWrapper>
        )
    }
});

export const NumericField = observer(class TextField extends Component<TextFieldProps> {
    static defaultProps = {
        disabled: false,
        precision: 2
    };

    handleChange = (value: number) => {
        // TODO: Clamp min/max values

        this.props.onChange({
            type: "number",
            number: value
        });
    }

    render() {
        let value = this.props.state?.effectiveValue?.number;

        const disabled = this.props.descriptor.disabled || this.props.state?.disabled;

        // TODO: Render validation messages
        // TODO: Render hint

        return (
            <UI.FieldWrapper flex={this.props.flex} title={this.props.descriptor.title}>
                <UI.NumericInput
                    value={value}
                    placeholder={this.props.descriptor.placeholder}
                    disabled={disabled}
                    precision={this.props.descriptor.precision}
                    prefixUnit={this.props.descriptor.prefixUnit}
                    suffixUnit={this.props.descriptor.unit}
                    showResetButton={this.props.state?.overruled}
                    onChange={this.handleChange}
                    onReset={this.props.onReset}
                />
            </UI.FieldWrapper>
        )
    }
});

export const DateField = observer(class DateField extends Component<{}> {
    handleChange = (value: Date) => {
        this.props.onChange({
            type: "date",
            date: value
        });
    }

    render() {
        let value = this.props.state?.effectiveValue?.date;

        // TODO: Render validation messages
        // TODO: Render hint

        return (
            <UI.FieldWrapper flex={this.props.flex} title={this.props.title}>
                <UI.DatePicker
                    value={value}
                    disabled={this.props.state?.disabled}
                    showResetButton={this.props.state?.overruled}
                    onChange={this.handleChange}
                    onReset={this.props.onReset}
                />
            </UI.FieldWrapper>
        )
    }
});

export const DropdownField = observer(class DropdownField extends Component<{}> {
    handleChange = (value: string) => {
        this.props.onChange({
            type: "string",
            string: value.identifier
        });
    }

    render() {
        let value = this.props.state?.effectiveValue?.string;

        let options = this.props.options;

        // Filter options by group if present
        const group = this.props.state?.group?.string;
        if (!!group) {
            options = options.filter(function(option) {
                return option.group === group;
            });
        }

        // TODO: Render validation messages
        // TODO: Render hint

        return (
            <UI.FieldWrapper flex={this.props.flex} title={this.props.title}>
                <UI.Dropdown
                    value={value}
                    options={options}
                    disabled={this.props.state?.disabled}
                    onChange={this.handleChange}
                    onReset={this.props.onReset}
                />
            </UI.FieldWrapper>
        )
    }
});

export const SegmentedControlField = observer(class SegmentedControlField extends Component<{}> {
    handleChange = (value: string) => {
        this.props.onChange({
            type: "string",
            string: value.identifier
        });
    }

    render() {
        let value = this.props.state?.effectiveValue?.string;

        // TODO: Render validation messages
        // TODO: Render hint

        return (
            <UI.FieldWrapper flex={this.props.flex} title={this.props.title}>
                <UI.SegmentedControl
                    value={value}
                    options={this.props.options}
                    disabled={this.props.state?.disabled}
                    onChange={this.handleChange}
                    onReset={this.props.onReset}
                />
            </UI.FieldWrapper>
        )
    }
});

export const NumericSegmentedControlField = observer(class NumericSegmentedControlField extends Component<{}> {
    handleChange = (value: number) => {
        this.props.onChange({
            type: "number",
            number: value
        });
    }

    render() {
        let value = this.props.state?.effectiveValue?.number;

        const disabled = this.props.descriptor.disabled || this.props.state?.disabled;

        // TODO: Render validation messages
        // TODO: Render hint

        return (
            <UI.FieldWrapper flex={this.props.descriptor.flex} title={this.props.descriptor.title}>
                <UI.NumericSegmentedControl
                    value={value}
                    disabled={disabled}
                    placeholder={this.props.descriptor.placeholder}
                    onChange={this.handleChange}
                    onReset={this.props.onReset}
                />
            </UI.FieldWrapper>
        )
    }
});

export const MultiCheckboxField = observer(class MultiCheckboxField extends Component<{}> {
    handleChange = (value: [Object]) => {
        this.props.onChange(value.map(function(option) {
            return {
                type: "string",
                string: option.identifier
            };
        }));
    }

    render() {
        let value = (this.props.state?.effectiveValue || []).map(value => {
            return this.props.options.find(function(option) {
                return option.identifier === value.string
            });
        });

        // TODO: Render validation messages
        // TODO: Render hint

        return (
            <UI.FieldWrapper flex={this.props.flex} title={this.props.title}>
                <UI.MultiCheckbox
                    value={value}
                    options={this.props.options}
                    disabled={this.props.state?.disabled}
                    onChange={this.handleChange}
                    onReset={this.props.onReset}
                />
            </UI.FieldWrapper>
        )
    }
});

export const BuilderField = observer(class BuilderField extends Component<{}> {
    handleAdd = () => {
        this.props.onAddItem(this.props.subformIdentifier);
    }

    render() {
        let items = (this.props.state?.items || []).map(function(item, index) {
            let form = item.form.get();

            // TODO: Reorder buttons

            return (
                <Provider key={"subform" + item.key} form={form}>
                    <UI.Row>
                        <UI.Flex>
                            <SubformView view={form.view} />
                        </UI.Flex>
                        <UI.Spacer width={10} />
                        <UI.Button icon="times" onPress={item.removeItem.get()} />
                    </UI.Row>
                </Provider>
            );
        });

        items.push(
            <UI.Row key="buttons">
                <UI.Button icon="plus" onPress={this.handleAdd} />
            </UI.Row>
        );

        return (
            <UI.FieldWrapper title={this.props.title}>
                {UI.spacePad(items, { height: 10 })}
            </UI.FieldWrapper>
        );
    }
});

export const ImmoTaxationReportPhotosField = observer(class ImmoTaxationReportPhotosField extends Component<{}> {
    handleSwap = (firstSlot, secondSlot) => {
        // Copy the value
        let photos = (this.props.state?.effectiveValue || []).slice();

        // Ensure we have enough slots available
        while (photos.length <= Math.max(firstSlot, secondSlot)) {
            photos.push(null);
        }

        // Swap items
        const tmp = photos[firstSlot];
        photos[firstSlot] = photos[secondSlot];
        photos[secondSlot] = tmp;

        this.props.onChange(photos);
    }

    handleUpload = (slot) => {
        // TODO

        // Copy the value
        let photos = (this.props.state?.effectiveValue || []).slice();

        // Ensure we have enough slots available
        while (photos.length <= slot) {
            photos.push(null);
        }

        photos[slot] = "new item identifier";

        this.props.onChange(photos);
    }

    render() {
        let photos = (this.props.state?.effectiveValue || []).map(value => {
            if (!value) {
                return null;
            }

            return {
                identifier: value.string,
                url: "https://www.deboerenpartners.be/whise_images/3613719/large/20367182.jpg"
            };
        });

        return (
            <UI.ImmoTaxationReportPhotosField
                photos={photos}
                onSwap={this.handleSwap}
                onUpload={this.handleUpload}
            />
        );
    }
});
