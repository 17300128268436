import React, { Component } from "react";

import { observer, inject } from "mobx-react";

import Field from "./Field";

import * as UI from "./UI";

export const Box = inject("form")(observer(class _Box extends Component<{}> {
    static defaultProps = {
        wrapper: UI.Box
    }

    render() {
        // Return nothing if we are invisible
        let hidden = false;
        if (this.props.box.identifier) {
            let state = this.props.form.store[this.props.box.identifier];
            if (state && !state.visible) {
                return null;
                hidden = true;
            }
        }

        let contents = [];

        this.props.box.children.forEach((component, index) => {
            switch (component.type) {
                case "box":
                    contents.push(
                        <Box
                            key={"box" + index}
                            box={component}
                        />
                    );
                    break;
                case "panel":
                    contents.push(
                        <Panel
                            key={"panel" + index}
                            panel={component}
                        />
                    );
                    break;
                case "section":
                    contents.push(
                        <Section
                            key={"section" + index}
                            section={component}
                        />
                    );
                    break;
                case "field":
                    contents.push(
                        <Field 
                            key={"field" + index}
                            field={component}
                        />
                    );
                    break;
                case "empty_space":
                    contents.push(
                        <UI.EmptySpace
                            key={"space" + index}
                            flex={component.flex}
                        />
                    );
                    break;
                default:
                    contents.push(<UI.Error key={"item" + index}>Warning: unsupported box child node type found: {component.type}</UI.Error>);
                    console.log("Warning: unsupported box child node type found: " + component.type);
                    break;
            }
        });

        return (
            <this.props.wrapper flex={this.props.box.flex} flexDirection={this.props.box.flex_direction} hidden={hidden}>
                {contents}
                {/*UI.spacePad(contents, this.props.box.flex_direction === "row" ? { width: this.props.box.spacing } : { height: this.props.box.spacing })*/}
            </this.props.wrapper>
        );
    }
}));

export const Section = inject("form")(observer(class _Section extends Component<{}> {
    render() {
        // Return nothing if we are invisible
        if (this.props.section.identifier) {
            let state = this.props.form.store[this.props.section.identifier];
            if (state && !state.visible) {
                return null;
            }
        }

        let contents = [];

        this.props.section.children.forEach((component, index) => {
            switch (component.type) {
                case "box":
                    contents.push(
                        <Box 
                            key={"box" + index}
                            box={component}
                        />
                    );
                    break;
                case "field":
                    contents.push(
                            <Field
                                key={component.identifier}
                                field={component}
                            />
                    );
                    break;
                default:
                    contents.push(<UI.Error key={"item" + index}>Warning: unsupported section child node type found: {component.type}</UI.Error>);
                    console.log("Warning: unsupported section child node type found: " + component.type);
                    break;
            }
        });

        return (
            <>
                <UI.Title>{this.props.section.title.toUpperCase()}</UI.Title>
                <UI.Box flex={this.props.section.flex} flexDirection={this.props.section.flex_direction}>
                    {contents}
                </UI.Box>
                {/*UI.spacePad(contents, { height: 20 })*/}
            </>
        );
    }
}));

export class Panel extends Component<{}> {
    render() {
        return (
            <Box box={this.props.panel} wrapper={UI.Panel} />
        );
    }
}

export class SubformView extends Component<{}> {
    render() {
        let contents = [];

        this.props.view.children.forEach((component, index) => {
            switch (component.type) {
                case "box":
                    contents.push(
                        <Box
                            key={"box" + index}
                            box={component}
                        />
                    );
                    break;
                case "section":
                    contents.push(
                        <Section
                            key={"item" + index}
                            section={component}
                        />
                    );
                    break;
                case "field":
                    contents.push(
                        <UI.Row key={"item" + index}>
                            <Field
                                key={component.identifier}
                                field={component}
                            />
                        </UI.Row>
                    );
                    break;
                default:
                    contents.push(<UI.Error key={"item" + index}>Warning: unsupported subform child node type found: {component.type}</UI.Error>);
                    console.log("Warning: unsupported subform child node type found: " + component.type);
                    break;
            }
        });

        return (
            <>
                {UI.spacePad(contents, { height: 20 })}
            </>
        );
    }
}

export class Page extends Component<{}> {
    render() {
        let contents = [];

        this.props.page.children.forEach((component, index) => {
            switch (component.type) {
                case "box":
                    contents.push(
                        <Box
                            key={"box" + index}
                            box={component}
                        />
                    );
                    break;
                case "panel":
                    contents.push(
                        <Panel
                            key={"panel" + index}
                            panel={component}
                        />
                    );
                    break;
                default:
                    contents.push(<UI.Error key={"item" + index}>Warning: unsupported page child node type found: {component.type}</UI.Error>);
                    console.log("Warning: unsupported page child node type found: " + component.type);
                    break;
            }
        });

        return (
            <>
                {UI.spacePad(contents, { width: 20 })}
            </>
        );
    }
}
