export default class RuleContext {
    functions = {
        equals(context, lhs, rhs) {
            return context.makeBoolean(context.extractComparable(context.evaluate(lhs)) === context.extractComparable(context.evaluate(rhs)));
        },

        not_equals(context, lhs, rhs) {
            return context.makeBoolean(context.extractComparable(context.evaluate(lhs)) !== context.extractComparable(context.evaluate(rhs)));
        },

        greater(context, lhs, rhs) {
            return context.makeBoolean(context.extractComparable(context.evaluate(lhs)) > context.extractComparable(context.evaluate(rhs)));
        },

        if(context, condition, consequent, alternative) {
            return context.extractComparable(context.evaluate(condition)) ? context.evaluate(consequent) : context.evaluate(alternative);
        },

        read(context, identifier, defaultValue) {
            const value = context.store[identifier]?.effectiveValue;
            return ((value === null || value === undefined) && defaultValue !== undefined) ? context.evaluate(defaultValue) : value;
        },

        list(context, ...contents) {
            return contents;
        },

        includes(context, needle, haystack) {
            let comparable = context.extractComparable(context.evaluate(needle));

            return context.evaluate(haystack).find(function(element) {
                return context.extractComparable(context.evaluate(element)) === comparable;
            }) !== undefined;
        },

        map(context, identifier, functor) {
            // TODO: Also make it work on lists
            const collection = context.store[identifier]?.items;
            if (collection === null || collection === undefined) {
                return null;
            }

            return collection.map(function(member) {
                return member.form.get().ruleContext.evaluate(functor);
            });
        },

        sum(context, list) {
            const collection = context.evaluate(list);
            if (collection === null || collection.length === 0) {
                return null;
            }

            let result = null;
            for (let member of collection) {
                const number = context.extractNumber(member);
                if (number === null) {
                    continue;
                }

                if (result === null) {
                    result = 0;
                }

                result += number;
            }

            return result === null ? result : context.makeNumber(result);
        },

        negate(context, number) {
            const num = context.extractNumber(context.evaluate(number));
            if (num === null) {
                return null;
            }

            return context.makeNumber(-num);
        },

        round(context, number, decimals = 0) {
            const num = context.extractNumber(context.evaluate(number));
            if (num === null) {
                return null;
            }

            if (decimals === 0) {
                return context.makeNumber(Math.round(num));
            } else {
                return context.makeNumber(+(num.toFixed(decimals)));
            }
        },

        multiply(context, lhs, rhs) {
            const lhsValue = context.extractNumber(context.evaluate(lhs));
            if (lhsValue === null) {
                return null;
            }

            const rhsValue = context.extractNumber(context.evaluate(rhs));
            if (rhsValue === null) {
                return null;
            }

            return lhsValue * rhsValue;
        },

        divide(context, lhs, rhs) {
            const lhsValue = context.extractNumber(context.evaluate(lhs));
            if (lhsValue === null) {
                return null;
            }

            const rhsValue = context.extractNumber(context.evaluate(rhs));
            if (rhsValue === null) {
                return null;
            }

            return lhsValue / rhsValue;
        },

        add(context, lhs, rhs) {
            const lhsValue = context.extractNumber(context.evaluate(lhs));
            if (lhsValue === null) {
                return null;
            }

            const rhsValue = context.extractNumber(context.evaluate(rhs));
            if (rhsValue === null) {
                return null;
            }

            return lhsValue + rhsValue;
        },

        subtract(context, lhs, rhs) {
            const lhsValue = context.extractNumber(context.evaluate(lhs));
            if (lhsValue === null) {
                return null;
            }

            const rhsValue = context.extractNumber(context.evaluate(rhs));
            if (rhsValue === null) {
                return null;
            }

            return lhsValue - rhsValue;
        }
    }

    constructor(store) {
        this.store = store;
    }

    makeString(value) {
        // TODO: Type checking/coercion
        return {
            type: "string",
            string: value
        };
    }

    makeBoolean(value) {
        // TODO: Type checking/coercion
        return {
            type: "boolean",
            boolean: value
        };
    }

    makeNumber(value) {
        // TODO: Type checking/coercion
        return {
            type: "number",
            number: value
        };
    }

    // Convert a JavaScript scalar to a value object, does nothing if it
    // already is a value object.
    makeValue(value) {
        if (value === null) {
            return null;
        }

        switch (typeof value) {
            case "object": return value;
            case "string": return this.makeString(value);
            case "boolean": return this.makeBoolean(value);
            case "number": return this.makeNumber(value);
            default: return null; // Unknown type
        }
    }

    // Extract the value from the value object so it can be used in comparisons
    extractComparable(value) {
        if (value === null) {
            return null;
        }

        switch (value.type) {
            case "string": return value.string;
            case "boolean": return value.boolean;
            case "number": return value.number;
            default: return null;
        }
    }

    // Extract or coerce a number from the value object. Null values are left
    // untouched.
    extractNumber(value) {
        if (value === null) {
            return null;
        }

        switch (value.type) {
            case "string": return parseFloat(value.string);
            case "boolean": return value.boolean ? 1 : 0;
            case "number": return value.number;
            default: return null;
        }
    }

    // Extract a boolean from the value object or coerce it if it isn't.
    // Returns true on a non-null non-boolean value.
    extractBoolean(value) {
        if (value === null) {
            return false;
        }

        switch (value.type) {
            case "string": return true;
            case "boolean": return value.boolean;
            case "number": return true;
            default: return true;
        }
    }

    // Evaluate expression, returns a value object or null
    evaluate(expression) {
        if (Array.isArray(expression)) {
            // Function
            const [name, ...args] = expression;
            return this.makeValue(this.functions[name](this, ...args));
        } else {
            // Constant, convert to value object
            return this.makeValue(expression);
        }
    }
}
